// Special fonts

@font-face {
  font-family: "NectoMono";
  src: url("../fonts/Necto-Mono.woff2") format("woff2"),
       url("../fonts/Necto-Mono.woff") format("woff");
}


.roboto-regular {
  font-family: "Roboto", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
.roboto-regular-italic {
  font-family: "Roboto", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: italic;
  font-variation-settings:
    "wdth" 100;
}

.roboto-bold {
  font-family: "Roboto", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
.roboto-bold-italic {
  font-family: "Roboto", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: italic;
  font-variation-settings:
    "wdth" 100;
}



.pt-serif-regular {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.pt-serif-bold {
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-style: normal;
}

.pt-serif-regular-italic {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: italic;
}

.pt-serif-bold-italic {
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-style: italic;
}


// Voor pagina opmaak - met navigatie in de bottom

/*
@media only screen and (max-width: 640px) {
    html, body {
        min-height: calc(100vh - 100px);
        margin: 0px
    }

    body {
        position: relative;
        padding-bottom: 100px;
    }
    body {
        background-color: lightblue;
    }




    h2 {
      margin-top: 0px;
      padding-top: 16px;
    }

    .footer {
      position: absolute;
      
      bottom: 50px;
      left: 0px;
      
      height: 50px;
      width: 100%;
        
      background-color: blue;
      color: white;
      text-align: center;
    }

    .stickyBottomNav {
       position: fixed;
       left: 0;
       bottom: 0;
       width: 100%;
       z-index: 5;
       background-color: red;
       color: white;
       text-align: center;
    }



}
*/



@media only screen and (max-width: 640px) {


    .fullpage{
        height: 100vh;
        width: 100vw;
        background-color: #edfcf3;
        position: fixed; /* Apply the sticky positioning */
        z-index: 20; /* Ensure the navbar is above other content */
        bottom: 0;
    }

    .sticky-footer {
      background-color: #fff;
      //color: #fff;
      position: fixed; /* Apply the sticky positioning */
      bottom: 0; /* Stick the element to the top of the viewport */
      z-index: 10; /* Ensure the navbar is above other content */
    }
}


@media only screen and (min-width: 641px) {

    .sticky-header {
      background-color: #ffffff;
      position: sticky; /* Apply the sticky positioning */
      top: 0; /* Stick the element to the top of the viewport */
      z-index: 100; /* Ensure the navbar is above other content */
      & li {
        text-transform: uppercase;
      }
    }
/*
    .sticky-header nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
    }

    .sticky-header ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
    }

    .sticky-header li {
      margin-right: 20px;
    }

    .sticky-header a {
      text-decoration: none;
      color: #fff;
    }
*/
}

// tot hier sticky navigation

h1 {
    font-size: 2.5rem;
    font-family: "Ubuntu Mono", sans;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-variation-settings:
        "wdth" 100;
}


// ecommerce header


        

.sticky.ecommerce-header {
    width: 100%;
    


    &.is-stuck {
        .logo {
            height: 52px;
            padding-bottom: .4rem;
            padding-left: 1rem;
        }
    }
  .logo {
    height: 60px;
    padding-left: 1rem;
  }


  li {
    display: inline-block;
    text-transform: uppercase;
  }

  a {
    color: $primary-color;
    fill: $primary-color;
    font-weight: bold;
    font-family: "PT SANS";
    font-size: large;
   
  }

  a:hover {
    color: $secondary-color;
    fill: $secondary-color;
    
  }
  .button.search-button {
    width: 39px;
    height: 39px;
    background-size: 20px 20px;
    background-color: darken($dark-gray,20%);
    background-image: none;
    background-repeat: no-repeat;
    background-position: center center;
    padding-left: 0;
    padding-right: 0;
    border-radius: 0px;
  }
}

/// ALGEMENE VORMGEVING ///


.button.round {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    color: white;
    background-color: $primary-color;
    padding: 0.7em .9em .65em;
    border-radius: 8px;
    margin-bottom: 15px;
    margin-top: 10px;
}

@media only screen and (min-width: 768px){
    .button.round {
        font-size: 17px;
    }
}


h2 {
    font-size: 1.6rem;
}
h3 {
    font-size: 1.3rem
}

@media only screen and (min-width: 768px){
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 1.6rem;
    }
}


/// ALGEMENE VORMGEVING: TOPMENU //

.gipsotopmenu {
    width: 100%;
    display: flex; 
    flex-direction: row; 
    flex-wrap: nowrap; 
    justify-content: space-between; 
    align-items: center;

    .placeforlogo {
        width: 200px;
        display: flex; 
        flex-direction: row; 
        flex-wrap: nowrap; 
        justify-content: 
        flex-start; align-items: flex-end;
    }
    .totherightoflogo {
        width: 100%;
        margin-left: 1rem;
        display: flex; 
        flex-grow: 1; 
        flex-direction: column; 
        flex-wrap: nowrap; 
        justify-content: flex-end; 
        align-items: center;
        max-width: 700px;

        .tagline {
            font-family:"Ubuntu Mono",sans;
            font-size: 18px;
            color:#2f6265; 
            display:flex; 
            flex-direction:row; 
            flex-wrap:nowrap;
            justify-content:center;
            width: 100%;
            background-color: #edfcf3;
        }

        .menuontop {
            width: 100%; 
            justify-content: space-between;
            margin-top: .5rem;
            font-weight: 700;
            font-size:12px;

            li a {
                padding: 0.3rem .4rem;
            }

            li.menutodrop {
                a {
                    padding: 0.3rem 1.4rem 0.3rem .4rem;
                }

                ul.menu {
                    padding: 1rem;
                    li a {
                        padding: 1rem 0;
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 768px) {

    .gipsotopmenu {

        .placeforlogo {
            width: 200px;
        }
        .totherightoflogo {
            max-width: 750px;

            .tagline {
                font-size: 20px;
            }

            .menuontop {
                font-size:15px;
            }
        }
    }

}

@media only screen and (min-width: 1024px) {

    .gipsotopmenu {

        .placeforlogo {
            width: 220px;
        }
        .totherightoflogo {
            max-width: 775px;

            .tagline {
                font-size: 24px;
            }

            .menuontop {
                font-size:20px;
            }
        }
    }

}


/// VORMGEVING HOMEPAGINA ///

.contentblok {
    margin-top: 3rem; 
    margin-bottom: 4rem;
}
.homeblok {
    h1 {
        color: #ffffff;
        background-color: #2f6265;
        padding: .4rem 1rem;
        font-size:1.2rem;
    }

    p.bijschrift {
        line-height: 1.2;
        margin-top: .5rem;
        font-size: smaller;
        font-weight: 400;
    }

    .firstcolumn {
        margin-bottom: 2rem;
    }

}


@media only screen and (min-width: 768px){
    .homeblok {
        h1 {
            font-size:1.6rem;
        }
    }
}


.containeraanpak {
    margin-bottom: 2rem; 
    padding-top: 20px;
    
    .realisatiefase {
        a img.rondetekening {
            display: block;
            width: 75%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            margin-top: 0px;

            &:hover {
                background-color: #edfcf3;
            }
        }
    }

}


.onlinecommunity {
    padding: 1rem; 
    background-color: #edfcf3;
}

@media only screen and (min-width: 640px){
    .onlinecommunity {
        height: 548px;
    }
}

.subnav {
    text-transform: uppercase;
    font-variant: all-small-caps;
    font-size: large;
    font-weight: 500;
    ul li.is-active a{
        background-color: #edfcf3;
        color: $primary-color;
    }
}


$marketing-site-hero-height: 65vh;

.marketing-site-hero {
  background: url('../img/coverfoto.png') top right no-repeat;
  height: $marketing-site-hero-height;
  background-size: cover;
  display: flex;
  align-items: center;

  @media screen and (min-width: 40em) {
    background-position: center center;
  }
}

.marketing-site-hero-content {
  max-width: $global-width;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;

  //h1 {
  //  color: $white;
  //  font-size: 40px;
  //}
  p {
    color: #cacaca;
  }

  .button.round {
    border-radius: 5000px;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 25px;
    color: $black;
  }
}


h2.hoofdtitel {
	margin-top: 6rem;
	margin-bottom: 1rem;
	color: $black;
}


.rechterpijl {
	width: 175px;
}



// NEWS CARD

.news-card-tag {
  margin-bottom: 0.5rem;

  .label {
    border-radius: 5000px;
    background-color: $news-card-label-background;
    color: $black;
    font-size: 11px;

    a {
      background-color: inherit;
      color: inherit;
    }

    &:hover,
    &:focus {
      background-color: $news-card-label-background-hover;

      a {
        background-color: inherit;
        color: $white;
      }
    }
  }
}

// NEWS CARD

// AGENDA

.work-feature-block {
    border: 0;
}
.accordion-item {
    background-color: #2b3a11;
    
    &.is-active {
        background-color: $primary-color;
        .accordion-title {
            color: #2b3a11;
        }
    }
    .accordion-title {
        color: $primary-color;
        font-size: 1.2rem;
        &:hover {
            background-color: $primary-color;
            color: white;
        }
        &:focus {
            background-color: $primary-color;
        }
        &::before {
            top: 48%;

        }
    }
}

// AGENDA
// FOOTER

#engadget-footer-sitemap-container #engadget-footer-sitemap .links .link-column:not(:last-child) {
    border-right: 1px solid #e6e6e6;
}

#engadget-footer-sitemap-container {
    margin-top: 3rem;
    background-color: #2b3a11;
    #engadget-footer-sitemap {
        a {
            color: $primary-color !important;
        }
        .links .link-column:not(:last-child) {
            border-right: 1px solid $primary-color;
        }    
    } 



}
#engadget-footer-contact-details-container {
    background-color: $primary-color;
    #engadget-footer-contact-details {
        color: #2b3a11;
        li a {
            color: #2b3a11 !important;
        }
        .footer-left ul {
            margin-left: 0px;
            margin-top: 5px;
        }
    }
}
#engadget-footer-social-container {
    background-color: white;
}
#engadget-footer-social-container #engadget-footer-social {
    max-width: 75rem;
    color: $black;
    padding: 1rem 0;
}

h2.whoswho {
    color: $primary-color;
    text-align: center;
}
// FOOTER

// DETAILPAGINA's
.subnav-hero-section {
    background: white;
}


.subnav-hero-section {
    margin-top:1rem;
    height: initial;
    text-align: left;
    .subnav-hero-headline {
        color: $primary-color;
    }
    p {
        font-size: 125%;
    }   
}


.subnav-hero-subnav {
    width: fit-content;
    position: initial;
    border-top: 1px solid $primary-color;
    border-bottom: 1px solid $primary-color;
    margin-bottom: 2rem;
    margin-top: 1rem;

    .sub-active {
        a {
            background-color: $primary-color;
            color: white;
        }   
    }

    li a {
        font-size: 0.75rem;
        color: $primary-color;
        padding: 0.7rem 1rem;
        &:hover {
            color: #2b3a11;
        }

    }
}

.afbeeldingdetail {
    max-width: 100%;
}
.sidetestimonial {
    position: relative;
    padding: 1rem 1rem;
    color: $secondary-color; 
    .quote {
        font-style: italic;
    }

    &:before {
        content:""; 
          background: $secondary-color; 
          position: absolute; 
          top: 0; 
          right: 0; 
          height: 5px; 
          width: 30%;
    }
    &:after {
        content:""; 
          background: $secondary-color; 
          position: absolute; 
          bottom: 0; 
          right: 0; 
          height: 5px; 
          width: 30%;
    }
}

.projectdata {
    margin: 2rem 0;
}

.breadcrumbs li:not(:last-child)::after {
    color: #eef7ab;
}


projectmap  {
    height: 400px;
    width: 100%;
    margin-bottom: 25px;
}


#map, #map2 {
      width: 100%;
      height: 400px;
    }

#Wop,#Wid,#W,#Dop,#Did,#D,#WopDop,#WopDid,#WopD,#WidDop,#WidDid, #WidD, #WDop, #WDid, #WD {
    height: 400px !important;
    width: 100% !important;
    margin-bottom: 25px;
    display: none;
}
#Wall {
    height: 400px;
    width: 100%;
    margin-bottom: 25px;
    display: block;
}
#smallkaart {
    height: 300px;
    width: 100%;
    margin-bottom: 25px;
    display: block;
}

#projectpaginakaart {
    width: 600px !important;
    height:300px !important;
    margin-top: 25px;
}


.button.icon {
    padding: 0.001em .4em .08em .4em;
    color: #2a3a17;
    font-size: 1.5rem;
}

#functie {
    margin-top:50px;
    margin-bottom: 20px;
}

#selectprojecten {
    height: 120px;
}
.button.controls {
    margin-left: auto;
    margin-right: auto;
    display: block;

    background: #333;
    color: $white;

    &:hover {
        background: $primary-color;
        color: $white;
    }

    &.mixitup-control-active {
        background: $primary-color;
        color: $black;
        &:hover {
            color: $white;
        }
    }
}



#subwonen, #subdagbesteding {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}


.projectmore {
    color: $black;

    &:hover {
        background-color: lighten($primary-color,25%);
    }
}

.projectlogo {
    max-width: 400px;
    max-height: 300px;
}

.projectadres {
    position: relative;
    margin: 15px 0 50px;
    padding: 15px 0;
    /* border-top: 1px solid black; */
    /* border-bottom: 1px solid black; */
}
.projectadres::before {
    position: absolute;
    content: ""; 
    display: block; 
    right: 0;
    margin-top: -7px;
    /* margin: 0 auto; This will center the border. */
    width: 20%; /* Change this to whatever width you want. */
    border-bottom: 1px solid black; 
}
.projectadres::after {
    position: absolute;
    content: ""; 
    display: block; 
    right: 0;
    margin-top: 7px;
    /* margin: 0 auto; This will center the border. */
    width: 20%; /* Change this to whatever width you want. */
    border-bottom: 1px solid black; 
}

.afsluiter {
    margin-top:25px;
    color: $primary-color;
}

.naarmailchimp {
    font-size: larger;
    font-weight: bold;
    margin-top: 20px;
}

fieldset.checkbox-group {
    padding-left: 0;
    margin-bottom: 15px;
}
label.checkboxhack {
    max-width: 800px;
    display: block;
    margin-top: -35px;
    margin-left: 25px;
    margin-right: 0px;
}

.projectmapmarker {
    font-size: 25px;
}
.testimonialband {
    background-color: rgba($primary-color, 0.15);
    margin: 2rem 0;
}
.quoteplace {
    position:relative;
}
.lquotesign {
    position: absolute;
    top: .5rem;
    font-size: 6vw;
    right: 0;
    color: $primary-color;
}
.rquotesign {
    position:absolute;
    bottom: 2rem;
    font-size: 6vw;
    left: 0;
    color: $primary-color;
}

.projecttestimonial {
    margin-top: 2rem;
    margin-bottom: 0rem;
}

.testimonialcompleet {
    margin-top: 2rem;
}

.kalenderitem {
    margin-top: 11px;
    margin-bottom: 30px;
    .wanneer {
        padding-right: 10px;
        font-size: 1.4rem;
        color: white;
        background-color: #2b3a11; 
    }
    .button {
        font-size: 1.4rem;
        padding: .5rem 0 ;
        &::before{
            font-family: FontAwesome;
            content: "\f0a4   ";
        }
    }
    
}
span.thema {
    margin: 2px 0;
    border: 2px solid $primary-color;
    padding: 2px 11px;
    display: inline-block;
    width: 217px;
}

.formtitle {
    font-size: 1rem;
    font-weight: bold;
    color: $primary-color;
}

.formwarning {
    font-size: small;
}
.formbevestiging {
    font-weight: bold;
}
// SLIDER CODE HOMEPAGE

.confirmemail, .formids {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

// @import 'bourbon';
/*---- NUMBER OF SLIDE CONFIGURATION ----*/
$num-of-slide: 3;

.testimonialwrapper {
    //max-width: 60em;
    margin: 0 auto;
    position: relative;

    input {
        display: none;
    }
    .inner {
        width: percentage($num-of-slide);
        line-height: 0;
    }
    article {
        width: percentage(1/$num-of-slide);
        float: left;
        position: relative;

        img {
            width: 100%;
        }
    }
}







/*---- SET UP CONTROL ----*/
.slider-prev-next-control {
    height: 50px;
    position: absolute;
    top: 45%;
    width: 100%;
    translateY:-50%;

    label {
        display: none;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #fff;
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }
    }
}

.slider-dot-control {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;

    label {
        cursor: pointer;
        border-radius: 5px;
        display: inline-block;
        width: 10px;
        height: 10px;
        background: #bbb;
        //@include transition(all 0.3s);

        &:hover {
            background: #ccc;
            border-color: #777;
        }
    }
}

/* Info Box */
.info {
    position: absolute;
    font-style: italic;
    line-height: 20px;
    opacity: 0;
    color: #000;
    text-align: left;
    //@include transition(all 1000ms ease-out 600ms);

    h3 {
        color: #fcfff4;
        margin: 0 0 5px;
        font-weight: normal;
        font-size: 1.5em;
        font-style: normal;
    }

    &.top-left {
        top: 30px;
        left: 30px;
    }

    &.top-right {
        top: 30px;
        right: 30px;
    }

    &.bottom-left {
        bottom: 30px;
        left: 30px;
    }

    &.bottom-right {
        bottom: 30px;
        right: 30px;
    }
}

/* Slider Styling */
.slider-wrapper {
    width: 100%;
    overflow: hidden;
    //border-radius: 5px;
    //box-shadow: 1px 1px 4px #666;
    //background: #fff;
    //background: #fcfff4;
    translateZ:0;
    //@include transition(all 500ms ease-out);

    .inner {
        translateZ:0;
        //@include transition(all 800ms cubic-bezier(0.77, 0, 0.175, 1));
    }
}

/*---- SET POSITION FOR SLIDE ----*/
%bind-prev-next-button {
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    margin: 0;
    line-height: 38px;
    font-size: 3em;
    display: block;
    color: #777;
}

%bind-next-button {
    @extend %bind-prev-next-button;
    content: "\f105";
    padding-left: 15px;
}

%bind-next-label {
    display: block;
    float: right;
    margin-right: 35px;
}

%bind-prev-label {
    display: block;
    float: left;
    margin-left: 5px;
}

%bind-prev-button {
    @extend %bind-prev-next-button;
    content: "\f104";
    padding-left: 8px;
}

%bind-background-active-dot {
    background: #333;
}

%bind-opacity-info {
    opacity: 1;
}

@for $i from 1 through $num-of-slide {
    #slide#{$i}:checked {
        & ~ .slider-wrapper .inner {
            margin-left: percentage(1 - $i);
        }

        & ~ .slider-dot-control label:nth-child(#{$i}) {
            @extend %bind-background-active-dot;
        }

        & ~ .slider-wrapper article:nth-child(#{$i}) .info {
            @extend %bind-opacity-info;
        }
    }
}

@for $i from 1 through ($num-of-slide - 1) {
    #slide#{$i}:checked {
        & ~ .slider-prev-next-control label:nth-child(#{$i +1}) {
            @extend %bind-next-label;

            &::after {
                @extend %bind-next-button;
            }
        }
    }
}

#slide#{$num-of-slide}:checked ~ .slider-prev-next-control label:nth-child(1) {
    @extend %bind-next-label;

    &::after {
        @extend %bind-next-button;
    }
}

@for $i from 2 through $num-of-slide {
    #slide#{$i}:checked {
        & ~ .slider-prev-next-control label:nth-child(#{$i - 1}) {
            @extend %bind-prev-label;

            &::after {
                @extend %bind-prev-button;
            }
        }
    }
}

#slide#{1}:checked ~ .slider-prev-next-control label:nth-child(#{$num-of-slide}) {
    @extend %bind-prev-label;

    &::after {
        @extend %bind-prev-button;
    }
}

/*---- TABLET ----*/
@media only screen and (max-width: 850px) and (min-width: 450px) {
    .slider-wrapper {
        border-radius: 0;
    }
}

/*---- MOBILE----*/
@media only screen and (max-width: 450px) {
    .slider-wrapper {
        border-radius: 0;
    }

    .slider-wrapper .info {
        opacity: 0;
    }
}

/*---- REDACTOR CUSTOMISATION FOR POPUPS----*/

p[data-open$='popup']{
    color: $primary-color;
    cursor: pointer;
    border: 1px solid $primary-color;
    padding-left: 10px;
    &:after {
        font-family: 'Font Awesome 5 Free';
        content: "\f49e";
        font-weight: 900;
    }
}

h1.titelkalender {
    color:$primary-color;
    margin-top:20px;
    margin-bottom:10px;
}

.kalender {
    
    .kalicoon {
        color: #b2c801;
        margin-right: 25px;
        font-size: 12em;
    }
    .kalitem {
        margin-top: 25px;
        .kalwat {
            font-weight: bold;
            font-size: larger;
        }
    }

}

h3.zoekertjestitel {
margin-top: 3rem;
    background-color: #2b3a11;
    color: $primary-color;
    padding: 10px;
}

.zoekertjesuitleg {
    background-color: rgba(178, 200, 1, 0.15);
    padding: 1em;
    color: black;
}

.half {
  float: left;
  width: 50%;
  padding: 0 1em;
}
/* Acordeon styles */
.csstab {
    position: relative;
    margin-bottom: 1px;
    width: 100%;
    color: #fff;
    overflow: hidden;
    background: $primary-color;

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
    
        
    label {
        position: relative;
        display: block;
        padding: 0 0 0 1em;
        font-weight: bold;
        line-height: 3;
        cursor: pointer;
        width: 100%;
        color: white;

        &::after {
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            width: 3em;
            height: 3em;
            line-height: 3.6;
            text-align: center;
            -webkit-transition: all .35s;
            -o-transition: all .35s;
            transition: all .35s;
        }
    }    
    /* :checked */
    
    input:checked ~ .tab-content {
        max-height: 100vh;
    }
    
    
    input[type=radio] + label {
        display: inline-block;
        vertical-align: baseline;
        margin-left: 0.5rem;
        margin-right: 1rem;
        margin-bottom: 0;
        padding: 1em 2em 1em 1em;
        line-height: 1.3em;

        &::after {
            content: "\25BC";
        }
    }
    
    input[type=radio]:checked + label {
        display: inline-block;
        vertical-align: baseline;
        margin-left: 0.5rem;
        margin-right: 1rem;
        margin-bottom: 0;
        color: black;
        &::after {
            transform: rotateX(180deg);
        }
    }
    .tab-content {
        max-height: 0;
        overflow: hidden;
        background: #1abc9c;
        -webkit-transition: max-height .35s;
        -o-transition: max-height .35s;
        transition: max-height .35s;
        background: white;
        color: black;
        h2 {
            color: $primary-color;
            margin-top: 2em;
            font-size: 1.5rem;
            line-height: 1.5rem;
            .searchicon {
                float: left;
            }
            .h2title {
                overflow: hidden;
                margin-left: 1.3em;
            }
        }

        p {
            margin: 1em 0 0 .2em;
            padding-left: 1em;
            line-height: 1.4;
            
            &.gepostop {
                font-size: small;
                color: $medium-gray;
                margin-left: .5em;
                margin-top: .5em;
                padding-left: 1em;
            }
        }
        a {
            margin: .5em 0 4em 1.2em;
        }
    }
}

#ouibounce-modal {
    font-family: 'Open Sans', sans-serif;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .underlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        cursor: pointer;
        -webkit-animation: fadein 0.5s;
        animation: fadein 0.5s;
    }
    .modal {
        width: 80%;
        height: 80%;
        background-color: #f0f1f2;
        z-index: 1;
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 4px;
        -webkit-animation: popin 0.3s;
        animation: popin 0.3s;
    
        .modal-title {
            font-size: 18px;
            background-color: #252525;
            color: #fff;
            padding: 10px;
            margin: 0;
            border-radius: 4px 4px 0 0;
            text-align: center;
        }
        h3 {
            color: #fff;
            font-size: 1em;
            margin: 0.2em;
            text-transform: uppercase;
            font-weight: 500;
        }
        .modal-body {
            padding: 20px 35px;
            font-size: 0.9em;
        
            p {
                color: #344a5f;
            }
            form {
                text-align: center;
                margin-top: 35px;
                input[type=text] {
                    padding: 12px;
                    font-size: 1.2em;
                    width: 300px;
                    border-radius: 4px;
                    border: 1px solid #ccc;
                    -webkit-font-smoothing: antialiased;
                }
                input[type=submit] {
                    text-transform: uppercase;
                    font-weight: bold;
                    padding: 12px;
                    font-size: 1.1em;
                    border-radius: 4px;
                    color: #fff;
                    background-color: #4ab471;
                    border: none;
                    cursor: pointer;
                    -webkit-font-smoothing: antialiased;
                }
                p {
                    text-align: left;
                    margin-left: 35px;
                    opacity: 0.8;
                    margin-top: 1px;
                    padding-top: 1px;
                    font-size: 0.9em;
                }
            }
        }

        .modal-footer {
            position: absolute;
            bottom: 20px;
            text-align: center;
            width: 100%;

            p {
                text-transform: capitalize;
                cursor: pointer;
                display: inline;
                border-bottom: 1px solid #344a5f;
            }   
        }
    }
}


@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@-webkit-keyframes popin {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  85% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-ms-keyframes popin {
  0% {
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  85% {
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes popin {
  0% {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  85% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}



